import React from "react";
import Translation from "../i18n";
import {connect} from "react-redux";
import {IAuthObject} from "../Interfaces/Redux";
import {IUser} from "../Interfaces/Common";
import {
  BenchmarkDuration,
  BenchmarkMetrics,
  SiteBenchmarkMetric,
  SiteBenchmarkMetrics
} from "connectel-shared/constants/Benchmark";
import {getBenchmarkData} from "../Store";
import {getBenchmarkDateRange} from "connectel-shared/utils/date";
import moment from "moment";
import { BenchmarkQueryType } from "connectel-shared/interfaces/Benchmark";

const Durations = [{
  "value": BenchmarkDuration.LAST_30_DAYS,
  "title": "Last 30 days"
}, {
  "value": BenchmarkDuration.THIS_MONTH,
  "title": "This month"
}, {
  "value": BenchmarkDuration.CUSTOM,
  "title": "Custom"
}]

const Months = []
Array(12).fill(12).forEach((_,i) => {
  const month = moment().startOf("year").add("month", i).format("MM");
  const startDate = moment(`${moment().format("YYYY")}-${month}-01 00:00:00`);
  Months.push({
    from: startDate.format("YYYY-MM-DD 00:00:00"),
    to: startDate.endOf("month").format("YYYY-MM-DD 23:59:59"),
    month: startDate.format("MMMM")
  })
})

interface IProps {
  auth: IAuthObject;
  users: Array<IUser>;
}

interface IState {
  benchmarkData: SiteBenchmarkMetrics;
  benchmarkAlias: string;
  benchmarkDuration: BenchmarkDuration;
  benchmarkCustomInterval: {month: string, from: string, to: string}
}

class Billing extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      benchmarkData: [],
      benchmarkAlias: BenchmarkMetrics[0].alias,
      benchmarkDuration: BenchmarkDuration.LAST_30_DAYS,
      benchmarkCustomInterval: null,
    };
  }

  async componentDidMount(): Promise<void> {
    const benchmarkData = await getBenchmarkData(this.state.benchmarkAlias);
    this.setState({
      benchmarkData: benchmarkData?.data
    });
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        {this.getHeader()}
        {this.getTable()}
      </React.Fragment>
    );
  }

  getHeader(): React.ReactNode {
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <h1>{t("CUSTOMER_OVERVIEW")}</h1>
          </React.Fragment>
        )}
      </Translation>
    );
  }

  getTable(): React.ReactNode {
    const from = this.state.benchmarkCustomInterval?.from ? this.state.benchmarkCustomInterval?.from : Months[0].from;
    const to = this.state.benchmarkCustomInterval?.to ? this.state.benchmarkCustomInterval?.to : Months[0].to;

    return (
      <React.Fragment>
        <div style={{padding: "20px 10px 10px 20px"}}>
          <select
            onChange={(event) => this.handleMetricChange("alias", event.target.value)}
            style={{padding: "10px"}}
          >
            {BenchmarkMetrics.map((metric) => {
              return <option value={metric.alias}>{metric.title}</option>;
            })}
          </select>
          <select
            onChange={(event) => this.handleMetricChange("duration", event.target.value)}
            style={{padding: "10px", margin: "10px"}}
          >
            {Durations.map((duration) => {
              return <option value={duration.value}>{duration.title}</option>;
            })}
          </select>
          {this.state.benchmarkDuration === BenchmarkDuration.CUSTOM && <select
            onChange={(event) => this.handleMetricChange("customInterval", event.target.value)}
            value={`${from}##${to}` || ""}
            style={{padding: "10px"}}
          >
            {Months.map((month) => {
              return <option value={`${month.from}##${month.to}`}>{month.month}</option>;
            })}
          </select>}
        </div>
        {/* <h1 style={{textAlign: "center"}}>Benchmark Portal Overview</h1> */}
        <div className="table-container">
          <table className="list users">
            <thead>
              <tr>
                <th>Name</th>
                {getBenchmarkDateRange(this.state.benchmarkDuration, 'YYYY-MM-DD', "ASC", from, to).map(
                  (date) => {
                    return <th>{moment(date).format("D MMM")}</th>;
                  }
                )}
                {this.state.benchmarkAlias === "VB_booking_success" && <th>Summary</th>}
              </tr>
            </thead>
            <tbody>
              {this.state.benchmarkData?.map((data) => {
                const totalCalls = data.values?.map(value => value?.metadata?.totalCalls ? parseInt(value?.metadata?.totalCalls as string) : 0)?.reduce((x, y) => x+y, 0)
                const allValues = data.values?.map(v => v.value).filter(val => val);
                if (allValues.length === 0) {
                  return <></>
                }

                return (
                  <tr>
                    <td>{data.site_name}</td>
                    {data.values.map((timeData) => {
                      const backgroundColor = this.getColumnColor(
                        data,
                        timeData
                      );

                      return (
                        <td
                          style={{backgroundColor}}
                          title={this.getMetricColumnTitle(data.metricAlias, timeData)}
                        >
                          {timeData?.metadata?.totalCalls ? `${timeData.value}(${timeData?.metadata?.totalCalls})` : (timeData.value || "-" )  }
                        </td>
                      );
                    })}
                    {this.state.benchmarkAlias === "VB_booking_success" && <td>{totalCalls}</td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  private async handleMetricChange(key: string, value: string) {
    const state = {...this.state}
    switch(key) {
      case "alias":
        state["benchmarkAlias"] = value
        break;
      case "duration":
        state["benchmarkDuration"] = value as BenchmarkDuration;
        break;
      case "customInterval":
        const dates = value.split("##");
        state["benchmarkDuration"] = BenchmarkDuration.CUSTOM;
        state["benchmarkCustomInterval"] = {from: dates[0], to: dates[1], month: null};
        break;
    }
    state.benchmarkCustomInterval = state.benchmarkDuration === BenchmarkDuration.CUSTOM &&
      this.state.benchmarkCustomInterval === null
      ? Months.find(m => m.month === moment().format("MMMM"))
      : state.benchmarkCustomInterval;
    const benchmarkData = await getBenchmarkData(state.benchmarkAlias, state.benchmarkDuration, state.benchmarkCustomInterval);
    this.setState({
      benchmarkAlias: state.benchmarkAlias,
      benchmarkData: benchmarkData.data,
      benchmarkDuration: state.benchmarkDuration,
      benchmarkCustomInterval: state.benchmarkCustomInterval || null
    });
  }

  private getColumnColor(
    data: SiteBenchmarkMetric,
    timeData: {
      date: string;
      value: number;
      average: number;
    }
  ): string {
    let backgroundColor: string = null;
    switch (data.queryType) {
      case BenchmarkQueryType.Min:
        backgroundColor =
          timeData.value <= timeData.average
            ? "rgb(174, 246, 173)"
            : "rgb(245, 206, 204)";
        backgroundColor = !timeData.value ? "white" : backgroundColor;
        break;
      case BenchmarkQueryType.Max:
        backgroundColor =
          timeData.value >= timeData.average
            ? "rgb(174, 246, 173)"
            : "rgb(245, 206, 204)";
        backgroundColor = !timeData.value ? "white" : backgroundColor;
    }
    return backgroundColor;
  }

  private getMetricColumnTitle(metricAlias: string, timeData): string {
    switch(metricAlias) {
      case "VB_booking_success":
        const average = timeData.average.toString();
        const totalCalls = timeData.metadata?.totalCalls || ""
        return timeData.metadata?.totalCalls ? `${average}(${totalCalls})` : average;
      default:
        return timeData.average.toString()
    }
  }
}

function mapStateToProps(state: IProps): IProps {
  return {
    auth: state.auth,
    users: state.users
  };
}

export default connect(mapStateToProps, {})(Billing);
